import "core-js/modules/es.array.push.js";
import * as local from '@/libs/local';
//import HelloWorld from '@/components/HelloWorld.vue';
import { showToast } from 'vant';
import { showSuccessToast, showFailToast } from 'vant';
import Vue from 'vue';
import * as api from '@/api';
import { ref } from 'vue';
import { Tab, Tabs } from 'vant';
import { Col, Row } from 'vant';
import { Popup } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Dialog } from 'vant';
import { NoticeBar } from 'vant';
import { NavBar } from 'vant';
import { Picker } from 'vant';
import { Empty } from 'vant';
import { BackTop } from 'vant';
import { Notify } from 'vant';
import { Uploader } from 'vant';
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
import Decimal from 'decimal.js';

// 引入英文语言包

import Header from '../lyout/header.vue';
import Footer from '../lyout/footer.vue';
import Ce from '../lyout/ce.vue';
export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Ce
  },
  data() {
    return {
      fileList: [],
      url: '',
      lang_list: {},
      select_lang: {},
      lang: {},
      cate: [],
      user: {},
      loading: false,
      finished: false,
      is_jia: false,
      page: 1,
      bank: [],
      qb: [],
      bank_list: [],
      select: {
        type: 0
      },
      select1: {
        type: 0
      },
      td_arr: [],
      address: [],
      show11: 0,
      select2: {
        type: 0
      },
      show12: 0
    };
  },
  computed: {
    result() {
      let num1 = new Decimal(this.select.fabi.lv);
      let num2 = new Decimal(this.select.price);
      return num1.times(num2).toFixed(2);
    }
  },
  created: function () {
    let _this = this;
    _this.get_imdex_data();
    this.code = this.$route.query.code ? this.$route.query.code : '0000';
  },
  methods: {
    handleInput(event) {
      // 使用正则表达式替换掉非数字字符  
      //      this.inputValue = this.inputValue.replace(/[^\d]/g, '');  

      // // 如果你不希望输入以0开头（除了0本身），可以添加以下逻辑  
      if (this.select.price !== '0' && this.select.price.charAt(0) === '0') {
        this.select.price = this.select.price.slice(1);
      }
      console.log(event);
      this.select.price = this.select.price.replace(/[^\d]/g, '');
    },
    onClickLeft() {
      api.chat(0);
    },
    back() {
      window.history.back();
    },
    onload: function (name = false) {
      if (this.is_jia) {
        return false;
      }
      this.is_jia = true;
      if (name) {
        this.goods = [];
        this.page = 1;
        this.finished = false;
        this.is_has = 1;
      }
      api.all('/api/user/user_tx', {
        page: this.page
      }, (err, data) => {
        if (!err && data.code === 1) {
          this.loading = false;
          this.is_jia = false;
          let oldArr = this.address;
          let newArr = data.data.dizhi;
          this.address = oldArr.concat(newArr);
          //this.banner = data.data.banner;

          if (data.data.count <= this.page * 20) {
            this.finished = true;
          }
          this.page++;
        } else {
          console.log(data);
        }
      });
    },
    get_imdex_data: function () {
      api.all('/api/user/user_apply', {}, (err, data) => {
        if (!err && data.code === 1) {
          this.lang_list = data.lang_list;
          this.select_lang = data.select_lang;
          this.lang = data.data.lang;
          this.cate = data.data.cate;
          this.user = data.data.user;
          this.bank = data.data.td;
          this.qb = data.data.qb;
          this.bank_list = data.data.dizhi;
          this.td_arr = data.data.td_arr;
        } else {
          console.log(data);
        }
      });
    },
    up: function () {
      if (this.select.price <= 0 || this.select.price == null || this.select.price == undefined) {
        showFailToast(this.lang.a11);
        return;
      }
      if (this.select.type == "0") {
        if (this.select.well_id == "" || this.select.well_id == null || this.select.well_id == undefined) {
          showFailToast(this.lang.a13);
          return;
        }
      }
      if (this.select.type == "1") {
        if (this.select.bank_id == "" || this.select.bank_id == null || this.select.bank_id == undefined) {
          showFailToast(this.lang.a2);
          return;
        }
      }
      let data = this.select;
      api.all('/api/user/user_tx_apply', data, (err, data) => {
        showToast(data.msg);
      });
    },
    bank111: function (id) {
      if (this.select.price <= 0 || this.select.price == null || this.select.price == undefined) {
        showFailToast(this.lang.a11);
        return;
      }
      let data = this.select;
      data.td_id = id;
      api.all('/api/user/user_bank_tx', data, (err, data) => {
        showToast(data.msg);
        if (data.code == 1) {
          window.location.href = data.data.url;
        }
      });
    },
    de: function (id) {
      api.all('/api/user/de_reward', {
        id: id
      }, (err, data) => {
        showToast(data.msg);
        this.get_imdex_data();
      });
    },
    show_d(type) {
      api.all('/api/user/user_apply', {
        gz_id: this.guize.data.id
      }, (err, data) => {
        showToast(data.msg);
        this.get_imdex_data();
      });
    },
    detail: function (id1) {
      let _this = this;
      _this.$router.push({
        name: 'detail',
        query: {
          id: id1
        }
      });
    },
    show_d1() {
      let _this = this;
      _this.$router.push({
        name: "well",
        query: {
          id: 1
        }
      });
      this.$nextTick(() => {
        window.scrollTo(0, 0); // 滚动到顶部
      });
    },
    show_d21() {
      let _this = this;
      _this.$router.push({
        name: "bank",
        query: {
          id: 1
        }
      });
      this.$nextTick(() => {
        window.scrollTo(0, 0); // 滚动到顶部
      });
    },
    up_bank: function () {
      let _this = this;
      let data = this.select2;
      data.type = this.show12;
      api.all('/api/user/user_bank', data, (err, data) => {
        showToast(data.msg);
        _this.get_imdex_data();
      });
    },
    show_d2(type) {
      this.show12 = type;
      if (type < 0) {
        this.select2 = {
          bank_name: "",
          bank_card: "",
          name: "",
          phone: "",
          email: "",
          is_mo: false
        };
        console.log(1);
      } else {
        this.select2 = this.address[type];
      }
    },
    up_well: function () {
      let data = this.select1;
      let _this = this;
      data.type = this.show11;
      api.all('/api/user/user_well', data, (err, data) => {
        showToast(data.msg);
        _this.get_imdex_data();
      });
    }
  }
};